<template>
  <div class="row full-width full-height">

    <!-- description for seo -->
    <div class="hidden column full-width q-pt-lg">
      <div :class="['col-12 col-md', isMobile ? 'q-mb-md' : undefined]">
        <div :class="['relative-position column', isMobile ? 'q-gutter-y-md' : 'q-gutter-y-lg']">

          <!-- title -->
          <h1 class="text-subtitle1 text-weight-bolder text-center text-grey-10 site-title q-mb-none">
            {{ tenantInfo && tenantInfo.details.siteHeading }}
          </h1>

          <!-- about -->
          <div class="line-height text-subtitle2 text-weight-light text-justify text-grey-9">
            <div v-if="tenantInfo" v-html="tenantInfo.about"
                 class="full-width ck-content"/>


          </div>

        </div>
      </div>
    </div>

    <div :class="['row full-width justify-center', isMobile ? 'q-py-lg' : 'q-py-lg']">
      <div class="column col-11 q-gutter-y-md">

        <!-- header -->
        <div :class="['row full-width items-stretch', isMobile ? undefined : 'q-px-sm']">

          <!-- carousel -->
          <div :class="['col-12 col-md-9', isMobile ? undefined : 'q-pr-md']">
            <s-banner-slider class="full-height" :slides="slides"/>
          </div>

          <!-- cards -->
          <div v-if="!isMobile" class="column col q-gutter-y-md">

            <!-- latest donation -->
            <div class="full-width col border-radius hover-shadow text-grey-4 bg-white overflow-hidden">
              <router-link v-if="latestDonation"
                           class="column full-width full-height q-pa-md justify-between text-grey-4"
                           style="user-select: none; text-decoration: none" tag="div"
                           :to="`/challenge/${latestDonation.projectId}`">

                <!-- header -->
                <div class="full-width col-auto">
                  <div class="row full-width justify-between items-center">

                    <!-- title -->
                    <div class="text-subtitle1 text-bold text-primary">
                      آخرین مشارکت
                    </div>

                    <!-- date, pulsating circle -->
                    <div class="row q-pr-sm q-gutter-x-md items-center">

                      <!-- date -->
                      <div class="text-caption text-grey-7">
                        {{ latestDonation.date.getElapsedTime() }}
                      </div>

                      <!-- pulsating circle -->
                      <div class="relative-position">
                        <div class="pulsating-circle"/>
                      </div>

                    </div>

                  </div>
                </div>

                <q-separator class="q-my-md"/>

                <!-- body -->
                <div class="full-width col">
                  <div class="row full-height">

                    <!-- logo -->
                    <div class="column col-auto q-pr-md justify-center items-center">
                      <q-img
                        :src="latestDonation.charityLogo && `${baseUrl}/cdn/download/${latestDonation.charityLogo.fileName}`"
                        width="80px" ratio="1"/>
                    </div>

                    <!-- details -->
                    <div class="column col q-pl-md justify-between" style="border-right: 1px solid">

                      <!-- sponsor -->
                      <div class="full-width">
                        <div class="row items-center">

                          <!-- label -->
                          <div class="col-auto">
                            <div class="text-subtitle2 text-weight-regular text-grey-8">
                              حامی:
                            </div>
                          </div>

                          <!-- sponsor -->
                          <div class="col q-pl-md">
                            <div class="line-clamp-1 text-right text-subtitle1 text-bold text-grey-8">
                              {{ latestDonation.sponsorTitle }}
                            </div>
                          </div>

                        </div>
                      </div>

                      <!-- project -->
                      <div class="full-width">
                        <div class="row items-center">

                          <!-- label -->
                          <div class="col-auto">
                            <div class="text-subtitle2 text-weight-regular text-grey-8">
                              پویش:
                            </div>
                          </div>

                          <!-- project -->
                          <div class="col q-pl-md">
                            <div class="line-clamp-1 text-right text-subtitle1 text-bold text-grey-8">
                              {{ latestDonation.projectTitle }}
                            </div>
                          </div>

                        </div>
                      </div>

                      <!-- amount -->
                      <div class="full-width">
                        <div class="row items-center">

                          <!-- label -->
                          <div class="col-auto">
                            <div class="text-subtitle2 text-weight-regular text-grey-8">
                              مبلغ:
                            </div>
                          </div>

                          <!-- amount -->
                          <div class="col q-pl-md">
                            <div class="line-clamp-1 text-right text-subtitle1 text-bold text-positive">
                              {{ latestDonation.amount && (latestDonation.amount / 10).toMoney('تومان') }}
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>
                </div>

              </router-link>
            </div>

            <!-- latest report -->
            <div class="full-width col border-radius hover-shadow text-grey-4 bg-white overflow-hidden">
              <router-link v-if="latestReport" class="column full-width full-height q-pa-md justify-between text-grey-4"
                           style="user-select: none; text-decoration: none" tag="div"
                           :to="`/report/${latestReport.id}`">

                <!-- header -->
                <div class="full-width col-auto">
                  <div class="row full-width justify-between items-center">

                    <!-- title -->
                    <div class="text-subtitle1 text-bold text-primary">
                      آخرین فعالیت
                    </div>

                    <!-- date, pulsating circle -->
                    <div class="row q-pr-sm q-gutter-x-md items-center">

                      <!-- date -->
                      <div class="text-caption text-grey-7">
                        {{ latestReport.date && latestReport.date.getElapsedTime() }}
                      </div>

                      <!-- pulsating circle -->
                      <div class="relative-position">
                        <div class="pulsating-circle"/>
                      </div>

                    </div>

                  </div>
                </div>

                <q-separator class="q-my-md"/>

                <!-- body -->
                <div class="full-width col">
                  <div class="row full-height">

                    <!-- logo -->
                    <div class="column col-auto q-pr-md justify-center items-center">
                      <q-img
                        :src="latestReport.charityLogo && `${baseUrl}/cdn/download/${latestReport.charityLogo.fileName}`"
                        width="80px" ratio="1"/>
                    </div>

                    <!-- details -->
                    <div class="column col q-pl-md justify-between" style="border-right: 1px solid">

                      <!-- title -->
                      <div class="full-width">
                        <div class="line-clamp-2 text-subtitle1 text-bold text-grey-8">
                          {{ latestReport.title }}
                        </div>
                      </div>

                      <!-- charity -->
                      <div class="full-width">
                        <div class="line-clamp-1 text-subtitle2 text-weight-regular text-grey-8">
                          {{ latestReport.charityTitle }}
                        </div>
                      </div>

                      <!-- project -->
                      <div class="full-width">
                        <div class="line-clamp-1 text-subtitle2 text-weight-regular text-grey-8">
                          {{ latestReport.projectTitle }}
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

              </router-link>
            </div>

            <!--            <a v-for="promotion of promotions && promotions.slice(0, 2)"-->
            <!--               class="col full-width border-radius bg-white overflow-hidden"-->
            <!--               :href="promotion.link" :target="promotion.isLinkExternal ? '_blank' : undefined">-->
            <!--              <q-img :src="promotion.image && `${baseUrl}/cdn/download/${promotion.image.fileName}`" width="100%"-->
            <!--                     height="100%">-->
            <!--                <template v-slot:loading>-->
            <!--                  <q-spinner-tail-->
            <!--                    color="grey-4"-->
            <!--                    size="xl"-->
            <!--                  />-->
            <!--                </template>-->
            <!--              </q-img>-->
            <!--            </a>-->

          </div>

        </div>

        <!-- campaigns -->
        <div
          v-if="!isMobile"
          :class="['row width-full bg-primary justify-center', isMobile ? 'q-px-xs q-py-md' : 'q-pl-md q-pr-sm q-py-md']">
          <div :class="['row col-11', isMobile ? undefined : 'items-stretch']">

            <!-- title, view all (mobile) -->
            <div v-if="isMobile" class="row col-12 q-mb-md justify-between items-center">

              <!-- title -->
              <div class="text-h6 text-bold text-white">
                آخرین پویش‌ها
              </div>

              <!-- view all -->
              <router-link style="text-decoration: none" tag="div" to="/challenges">
                <q-btn class="border-radius text-weight-regular q-px-md" style="border: 2px solid" color="white"
                       label="بیشتر" flat/>
              </router-link>

            </div>

            <!-- image, view all campaigns -->
            <div v-if="!isMobile" class="col-auto q-pr-sm">
              <div class="column full-height justify-end items-center">

                <!-- title -->
                <div class="column q-mb-lg items-center">
                  <div class="text-h4 text-bold text-white q-mb-xs">
                    آخرین
                  </div>

                  <div class="text-h5 text-bold text-white">
                    پویش‌ها
                  </div>
                </div>

                <!-- image -->
                <!--              <q-icon name="la la-stopwatch" size="90px" color="white"/>-->

                <!-- view all -->
                <router-link class="text-weight-regular text-white" style="text-decoration: none" tag="div"
                             to="/challenges">
                  مشاهده همه
                  <span class="q-ml-xs">
                  <q-icon name="la la-angle-left" size="10px"/>
                </span>
                </router-link>

              </div>
            </div>

            <!-- campaigns slider -->
            <div class="row col-12 col-md overflow-hidden">
              <q-carousel
                class="full-width bg-transparent"
                v-model="campaignSlide"
                ref="campaignCarousel"
                height="auto"
                :transition-next="isMobile ? 'jump-left' : 'slide-left'"
                :transition-prev="isMobile ? 'jump-right' : 'slide-right'"
                animated
                swipeable
              >

                <!-- slides -->
                <q-carousel-slide v-for="(x, index) of slideCount"
                                  class="no-padding" :name="index">
                  <div class="row q-col-gutter-md items-center">
                    <div v-for="campaign of campaigns.slice((index * slideCount), (index + 1) * (slideCount))"
                         class="col-12 col-md-3">
                      <campaign-item-mobile
                        :extra-details="true"
                        :id="campaign.id"
                        :limited="campaign.isLimited"
                        :charity-logo="campaign.charityLogo"
                        :title="campaign.title"
                        :charity-title="campaign.charityTitle"
                        :description="campaign.description"
                        :progress="campaign.progress"
                        :involvement-rate="campaign.involvementRate"
                        :money-raised="campaign.incomeAmount"
                        :estimated-cost="campaign.estimatedCost"
                        :remaining-days="campaign.remainingDays"
                        :sponsor-count="campaign.sponsorCount"
                        :status="campaign.statusLookup"
                      />
                    </div>
                  </div>
                </q-carousel-slide>

                <!-- custom controls -->
                <!--                <template v-slot:control>-->

                <!--                  &lt;!&ndash; previous slide &ndash;&gt;-->
                <!--                  <q-carousel-control class="column full-height justify-center"-->
                <!--                                      position="left" :offset="[0, 0]">-->
                <!--                    <div class="q-pa-xs bg-primary"-->
                <!--                         style="opacity: 70%; border-top-left-radius: 50%; border-bottom-left-radius: 50%">-->
                <!--                      <q-icon class="cursor-pointer" name="la la-angle-right" color="white"-->
                <!--                              size="xs"-->
                <!--                              @click="$refs.campaignCarousel.previous()"/>-->
                <!--                    </div>-->
                <!--                  </q-carousel-control>-->

                <!--                  &lt;!&ndash; next slide &ndash;&gt;-->
                <!--                  <q-carousel-control class="column full-height justify-center"-->
                <!--                                      position="right" :offset="[0, 0]">-->
                <!--                    <div class="q-pa-xs bg-primary"-->
                <!--                         style="opacity: 70%; border-top-right-radius: 50%; border-bottom-right-radius: 50%">-->
                <!--                      <q-icon class="cursor-pointer" name="la la-angle-left" color="white"-->
                <!--                              size="xs"-->
                <!--                              @click="$refs.campaignCarousel.next()"/>-->
                <!--                    </div>-->
                <!--                  </q-carousel-control>-->

                <!--                </template>-->

              </q-carousel>
            </div>

          </div>
        </div>

        <!-- quick access -->
        <div v-if="isMobile" class="row full-width relative-position">

          <!-- items -->
          <q-scroll-area class="full-width" style="height: 78px" :horizontal-thumb-style="{opacity: 0}" horizontal>
            <!--            @scroll="changeGradientVisibility"-->
            <div class="row full-width q-gutter-x-md no-wrap">
              <router-link v-for="(category, index) of (isMobile ? categoriesMobileCharity : categoriesMobile)"
                           :class="['col', index === 0 ? 'no-margin' : undefined]" style="text-decoration: none"
                           tag="div" :to="category.link">
                <div class="row full-width items-center">

                  <!-- icon -->
                  <div
                    class="row full-width border-solid bg-primary-1 q-mb-sm q-pa-sm justify-center text-secondary"
                    style="border-radius: 20px">
                    <q-icon :name="category.icon" size="32px" color="secondary"/>
                  </div>

                  <!-- title -->
                  <div class="col-12 text-caption text-weight-regular text-center text-secondary line-clamp-1">
                    {{ category.title }}
                  </div>

                </div>
              </router-link>
            </div>
          </q-scroll-area>

          <!-- left scroll indicator -->
          <!--          <div v-if="leftGradientVisibility" class="full-height absolute-right left-scroll-gradient"-->
          <!--               style="width: 10%"/>-->

        </div>

        <!-- campaigns (mobile) -->
        <div v-if="isMobile" class="column full-width">

          <!-- title, more -->
          <div class="row full-width q-mb-md q-pl-sm justify-between items-center text-secondary"
               style="border-right: 2px solid"
          >

            <!-- title -->
            <div class="text-subtitle1 text-weight-regular text-grey-9">
              پویش‌ها
            </div>

            <!-- more -->
            <router-link style="text-decoration: none" tag="div" to="/challenges">
              <div class="row q-gutter-x-xs items-center text-secondary">

                <div class="text-subtitle2 text-weight-regular">
                  بیشتر
                </div>

                <q-icon name="la la-angle-left" size="12px"/>

              </div>
            </router-link>

          </div>

          <!-- items -->
          <div class="column full-width q-gutter-y-md">
            <campaign-item-mobile
              v-for="campaign of campaigns && campaigns.slice(0, 4)"
              :extra-details="false"
              :show-description="false"
              :id="campaign.id"
              :limited="campaign.isLimited"
              :charity-logo="campaign.charityLogo"
              :icon="campaign.projectGroupIcon"
              :title="campaign.title"
              :charityTitle="campaign.charityTitle"
              :progress="campaign.progress"
              :moneyRaised="campaign.incomeAmount"
              :estimatedCost="campaign.estimatedCost"
              :status="campaign.statusLookup"
            />
          </div>

        </div>

        <!-- procedures (faq) -->
        <div v-if="!isMobile" class="column full-width items-center">

          <!-- title -->
          <div class="row full-width q-mb-lg q-px-sm">
            <div class="text-h6 q-my-none text-bold text-grey-9">
              حمایت در 3 کلیک!
            </div>
          </div>

          <!-- cards, view faq -->
          <div class="row full-width items-stretch">

            <!-- cards -->
            <router-link v-for="procedure of procedures" class="col q-px-sm cursor-pointer"
                         style="text-decoration: none"
                         :to="procedure.link">
              <div
                class="column full-width border-radius border-solid bg-white q-pa-lg justify-center items-center text-grey-4">
                <div class="column full-width q-gutter-y-lg items-center">

                  <!-- image -->
                  <q-img class="border-radius" :src="procedure.image" width="90%"/>

                  <!-- text -->
                  <h3 class="text-subtitle1 text-weight-regular text-grey-8 text-center">
                    {{ procedure.text }}
                  </h3>

                </div>
              </div>
            </router-link>

            <!-- view faq -->
            <router-link class="col q-px-sm" style="text-decoration: none" to="/faq">
              <div
                class="column full-width border-radius border-solid bg-white q-pa-md justify-center items-center text-grey-4">
                <div class="column full-width q-gutter-y-lg items-center">

                  <!-- image -->
                  <q-img class="border-radius" src="img/procedures/4.jpg" width="90%"/>

                  <!-- text -->
                  <h3 class="text-subtitle1 text-weight-regular text-grey-8 text-center">
                    سوال داری بپرس
                  </h3>

                </div>
              </div>
            </router-link>

          </div>

        </div>

        <!-- categories -->
        <div v-if="!isMobile && tenantInfo && tenantInfo.id === $hamianeTenantId"
             :class="['column full-width items-center', isMobile ? '' : undefined]">

          <!-- title, more -->
          <div :class="['row full-width q-mb-lg justify-between items-center', isMobile? '' : 'q-px-sm']">

            <!-- title -->
            <div class="text-h6 text-bold text-grey-9">
              دسته بندی
            </div>

            <!-- more -->
            <router-link style="text-decoration: none" tag="div" to="/challenges">
              <q-btn class="border-radius q-px-md" color="primary" label="بیشتر" outline dense/>
            </router-link>

          </div>

          <!-- banners -->
          <div :class="['row full-width', isMobile ? undefined : 'q-pr-sm']">
            <div class="row full-width q-gutter-y-sm justify-between items-stretch">
              <s-promotion v-for="category of categories" :size="category.size" :link="category.link"
                           :image="category.image"/>
            </div>
          </div>

        </div>

        <!-- recent blog posts -->
        <div v-if="!isMobile && blogPosts && blogPosts.length > 0" class="column full-width items-center">

          <!-- title, more -->
          <div
            class="row full-width q-mb-lg q-px-sm justify-between items-center">

            <!-- title -->
            <div class="text-h6 text-bold text-grey-9">
              خواندنی‌ها
            </div>

            <!-- more -->
            <router-link style="text-decoration: none" tag="div" to="/blog">
              <q-btn class="border-radius q-px-md" color="primary" label="بیشتر" outline dense/>
            </router-link>

          </div>

          <!-- posts -->
          <div class="row full-width q-px-sm justify-between items-stretch">

            <!-- latest post -->
            <div class="column col-4 border-radius border-solid overflow-hidden bg-white items-center text-grey-4">

              <!-- image -->
              <router-link class="full-width" style="text-decoration: none" tag="div" :to="`/blog/${blogPosts[0].id}`">
                <q-img
                  :src="blogPosts[0].image && `${baseUrl}/cdn/download/${blogPosts[0].image.fileName}`"
                  width="100%" height="250px"/>
              </router-link>

              <!-- details -->
              <div class="column full-width q-pa-lg q-gutter-y-md justify-center">

                <!-- title, date -->
                <div class="row full-width justify-between items-center">

                  <!-- title -->
                  <router-link style="text-decoration: none" tag="div" :to="`/blog/${blogPosts[0].id}`">
                    <div class="text-subtitle1 text-bold text-grey-9">
                      {{ blogPosts[0].title }}
                    </div>
                  </router-link>

                </div>

                <!-- intro -->
                <router-link style="text-decoration: none" tag="div" :to="`/blog/${blogPosts[0].id}`">
                  <div class="line-clamp-3 text-subtitle2 text-weight-regular text-justify text-grey-10" style="line-height: 24px">
                    {{ blogPosts[0].metaDescription }}
                  </div>
                </router-link>

                <!-- views, view post -->
                <div class="row full-width justify-between items-center">

                  <!-- views -->
                  <div class="row items-center">

                    <!-- views -->
                    <div class="text-caption text-grey-8">
                      {{ blogPosts[0].viewCount + ' بازدید' }}
                    </div>

                  </div>

                  <!-- date -->
                  <div class="row items-center">
                    <div class="text-caption text-grey-8">
                      {{ blogPosts[0].publishDate.getElapsedTime() }}
                    </div>
                  </div>

                </div>

              </div>

            </div>

            <!-- earlier posts -->
            <div v-if="blogPosts && blogPosts.length > 0"
                 class="column col-8 q-pl-md q-gutter-y-md justify-between items-end">
              <blog-post v-for="post of blogPosts.slice(1, 4)"
                         :post="post"
                         :isGridShow="false"
                         :showDescription="true"/>
            </div>
            <!-- tile -->

            <!-- tile end -->

          </div>

        </div>

        <!-- achievements -->
        <div v-if="!isMobile" class="column full-width items-center">

          <!-- title -->
          <div :class="['row full-width q-mb-lg', isMobile? '' : 'q-px-sm']">
            <div class="text-h6 text-bold text-grey-9">
              {{ `${tenantInfo && tenantInfo.title} به روایت آمار` }}
            </div>
          </div>

          <div id="achievements" class="row full-width items-stretch">
            <div v-for="(achievement, index) of achievements" class="col q-px-sm">
              <div
                class="column full-width border-radius border-solid bg-white q-py-xl justify-center items-center text-grey-5">

                <!-- title -->
                <div class="text-h3 text-bold text-primary q-mb-md">
                  {{ achievement.title + '+' }}
                </div>

                <!-- subtitle -->
                <div class="text-h6 text-bold text-grey-7">
                  {{ achievement.subtitle }}
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import CampaignItem from "../components/CampaignItem";
import CampaignItemMobile from "../components/CampaignItemMobile";
import CharityItem from "../components/CharityItem";
import BlogPost from "../components/blog/BlogPost";
import SBannerSlider from "../components/shared/SBannerSlider";
import SPromotion from "../components/shared/SPromotion";
import {LocalStorage} from "quasar";
import * as moment from "jalali-moment";
import {useMeta} from "quasar";

export default {
  async preFetch({store, currentRoute, previousRoute, redirect, ssrContext, urlPath, publicPath}) {
    if (ssrContext) {
      console.log('ssrContext : ',ssrContext.req.headers.host + currentRoute.path)
      await store.dispatch('getMeta', {pageUrl: ssrContext.req.headers.host + currentRoute.path})
    } else {
      console.log('ssrContext no : ',window.location.host + currentRoute.path)
      await store.dispatch('getMeta', {pageUrl: window.location.host + currentRoute.path})
    }

    let meta = {}
    meta.title = store.state.preFetch?.pageMeta?.metaTitle
    meta.description = store.state.preFetch?.pageMeta?.metaDescription
    meta.keywords = store.state.preFetch?.pageMeta?.metaKeywords

    // if (store.state.preFetch.latestDonationAndReport == null || process.env.SERVER)
    await store.dispatch('loadLatestDonationAndReport')
    // if (store.state.preFetch.banners == null || process.env.SERVER)
    await store.dispatch('loadBanners')
    // if (store.state.preFetch.projectTopList == null || process.env.SERVER)
    await store.dispatch('loadProjectTopList')
    // if (store.state.preFetch.blog == null || process.env.SERVER)
    await store.dispatch('loadBlog', {sortAsc: false, page: 1, take: 8})
    // if (store.state.preFetch.achievements == null || process.env.SERVER)
    if (store.state.tenant.id === process.env.HAMIANE_TENANT_ID) {
      console.log('* => getting hamiane report items')
      await store.dispatch('loadAchievements', {reportNames: 'HamianeFamilyMemberCount,HamianeProjectCount,HamianeSponsorCount,HamianeSupportPackItemCount'})
    } else {
      console.log(`* => getting ${store.state.tenant.name} report items`)
      await store.dispatch('loadAchievements', {reportNames: 'CharityFamilyMemberCount,ProjectCount,SponsorCount,SupportPackItemCount'})
    }

    let tenant = store.state.tenant
    let site = 'https://' + ssrContext.req.get('host');
    //let site = ssrContext.req.protocol + '://' + ssrContext.req.get('host');
    //console.log('reqq ================:' , ssrContext.req.protocol + '://' + ssrContext.req.get('host'))
    //await store.dispatch('loadTenantInfo')
    //let tenant = store.getters.getTenantInfo()
    //let site = 'https://www.hamiane.com';

    if(tenant.id == 18)
    meta.script = {
      ldJson: {
        type: 'application/ld+json',
        innerHTML: JSON.stringify(
          {
            "@context":"http://schema.org",
            "@graph": [
              {
                "@type":"Organization",
                "@id": site +"/#organization",
                "name": tenant.title,
                "alternateName": tenant.officialTitle,
                "url":"https://www.hamiane.com",
                "logo":"https://www.hamiane.com/img/logo-mobile.png",
                "contactPoint":[
                  {
                    "@type":"ContactPoint",
                    "telephone": tenant.phoneNumber,
                    "contactType":"customer service",
                    "areaServed":"IR",
                    "availableLanguage":"Persian"
                  },
                  {
                    "@type":"ContactPoint",
                    "telephone": tenant.phoneNumber,
                    "contactType":"sales",
                    "areaServed":"IR",
                    "availableLanguage":"Persian"
                  }
                ],
                "sameAs":[
                  "https://haminae.com/hamiane_com",
                  "https://www.instagram.com/hamiane_com/",
                  "https://linkedin.com/company/hamiane-com/"
                ]
              },
              {
                "@type": "WebSite",
                "@id": site +"/#webSite",
                "url": site,
                "potentialAction": {
                  "@type": "SearchAction",
                  "target": {
                    "@type": "EntryPoint",
                    "urlTemplate": site + "/search/{search_term_string}"
                  },
                  "query-input": "required name=search_term_string"
                }
              }
            ]
          }
        )
      },
    }
    return await store.dispatch('setMeta', meta)
  },
  name: 'Home',
  components: {
    CampaignItem,
    CampaignItemMobile,
    CharityItem,
    BlogPost,
    SBannerSlider,
    SPromotion
  },
  data: () => ({
    baseUrl: process.env.API_URL,
    isCampaignLoading: false,
    isCharityLoading: false,
    achievementsAnimation: true,
    rightGradientVisibility: false,
    leftGradientVisibility: true,
    slide: 0,
    showMore : false,
    activeReportSlide: 0,
    activeSection: 0,
    campaignSlide: 0,
    horizontalScrollPercentage: 0,
    charitySlide: 0,
    blogSlide: 0,
    barStyle: {
      borderRadius: '9px',
      backgroundColor: '#027be3',
      width: '5px',
      opacity: 0.2
    },
    thumbStyle: {
      borderRadius: '5px',
      backgroundColor: '#027be3',
      width: '5px',
      opacity: 0.75
    },
    categoriesMobile: [
      {title: 'یادمانه', icon: 'la la-gift', link: '/gift'},
      {title: 'صدقه', icon: 'la la-coins', link: '/challenges?page=1&filter=projectGroupId,in,1&sort='},
      {title: 'کاتالوگ', icon: 'la la-book', link: '/catalog'},
      {title: 'تعرفه‌ها', icon: 'la la-tag', link: '/pricing'},
      {title: 'بلاگ', icon: 'la la-blog', link: '/blog'},
      {title: 'مشارکت', icon: 'la la-handshake', link: '/donate'},
      {title: 'درباره ما', icon: 'la la-user-friends', link: '/about-us'}
    ],
    categoriesMobileCharity: [
      {title: 'مشارکت', icon: 'la la-handshake', link: '/donate'},
      {title: 'یادمانه', icon: 'la la-gift', link: '/gift'},
      {title: 'بلاگ', icon: 'la la-blog', link: '/blog'},
      {title: 'فعالیت‌ها', icon: 'la la-blog', link: '/reports'},
      {title: 'درباره ما', icon: 'la la-user-friends', link: '/about-us'}
    ],
    colors: ['pink', 'cyan', 'deep-purple'],
    achievements: [
      {name: 'FamilyMemberCount', title: null, subtitle: 'مهرجو'},
      {name: 'SponsorCount', title: null, subtitle: 'حامی'},
      {name: 'ProjectCount', title: null, subtitle: 'پویش'},
      {name: 'SupportPackItemCount', title: null, subtitle: 'اقلام بسته‌های حمایتی'}
    ],
    procedures: [
      {image: 'img/procedures/1.jpg', text: 'پویشی که دوس داری جستجو کن', link: '/challenges'},
      {image: 'img/procedures/2.jpg', text: 'آنلاین حمایت کن', link: '/donate'},
      {image: 'img/procedures/3.jpg', text: 'نتیجه حمایتت رو ببین', link: '/challenges'}
    ],
    links: [
      {title: 'کاتالوگ حامیانه', image: 'img/nav-1.png', icon: 'la la-map', link: '/catalog'},
      {title: 'مشارکت آنلاین', image: 'img/nav-2.png', icon: 'la la-handshake', link: '/donate'},
      {title: 'درباره ما', image: 'img/nav-3.png', icon: 'la la-info-circle', link: '/about-us'}
    ]
  }),
  computed: {
    isMobile() {
      return this.$q.screen.lt.md
    },
    slideCount() {
      return this.isMobile ? 1 : 4
    },
    slides() {
      return this.$store.state.preFetch.banners.filter(x => x.name.includes('slider'))
    },
    latestDonation() {
      return this.$store.state.preFetch.latestDonationAndReport?.donation
    },
    latestReport() {
      return this.$store.state.preFetch.latestDonationAndReport?.report
    },
    campaigns() {
      return this.$store.state.preFetch.projectTopList
    },
    categories() {
      return this.$store.state.preFetch.banners.filter(x => x.name.includes('category'))
    },
    blogPosts() {
      return this.$store.state.preFetch.blog
    },
    tenantInfo() {
      return this.$store.state.tenant
    },
    reports() {
      return this.$store.state.preFetch.banners.filter(x => x.name.includes('report'))
    }
  },
  methods: {
    // async loadCharities() {
    //   this.isCharityLoading = true
    //   let params = {take: 2}
    //   const res = await this.$axios.get(`${process.env.API_URL}/Charity/Charity/list`, {params})
    //   this.charities = res.data.result.items
    //   this.isCharityLoading = false
    // },
    async getAchievements() {
      this.achievements.forEach(x => {

          let achievements = [...this.$store.state.preFetch.achievements]
          if (achievements && achievements.length > 0) {
            let i = achievements.findIndex(y => Object.keys(y[0]).includes(x.name))

            let item = achievements[i]
            if (item != null && item[0] != null)
              x.title = item[0][x.name]
            else
              x.title = 0
          }
        }
      )
    },
    getYear(date) {
      return moment(date).format('jYYYY')
    },
    animateNumber(index) {
      let limit = this.achievements[index].title
      this.achievements[index].title = 0
      let step = parseInt((limit / 200).toFixed(0).toString())
      if (step <= 0) step = 1
      let incNum = setInterval(() => {
        this.achievements[index].title += step
        if (this.achievements[index].title >= limit) {
          clearInterval(incNum)
          this.achievements[index].title = limit
        }
      }, 100 / limit)
    },
    changeActiveReportSlide(slide) {
      setTimeout(() => {
        this.activeReportSlide = slide
      }, 10)
    },
    changeGradientVisibility(info) {
      this.rightGradientVisibility = info.horizontalPosition !== 0
      this.leftGradientVisibility = (info.horizontalContainerSize - info.horizontalSize) !== info.horizontalPosition
    }
  },
  async mounted() {
    useMeta(this.$store.state.meta)
    await this.getAchievements()
    if (!this.isMobile) {
      let observer = new IntersectionObserver((entries) => {
        if (this.achievementsAnimation === true && entries[0].isIntersecting === true) {
          this.animateNumber(0)
          this.animateNumber(1)
          this.animateNumber(2)
          this.animateNumber(3)
          this.achievementsAnimation = false
        }
      }, {threshold: [1]})
      observer.observe(document.querySelector('#achievements'))
    }
    this.$store.commit('setPageLoadState', true)
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 20px !important;
  line-height: 38px;
}

.pulsating-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: var(--q-primary);
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--q-primary);
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

.right-scroll-gradient {
  background: rgb(245, 245, 245);
  background: linear-gradient(90deg, rgba(245, 245, 245, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.left-scroll-gradient {
  background: rgb(245, 245, 245);
  background: linear-gradient(270deg, rgba(245, 245, 245, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}
.description-height {
  height: 250px;
  overflow: hidden;
}
.shadow-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 250px;
  background-image: linear-gradient(180deg,rgba(255,255,255,0) 0%,#f5f5f5 90%);
  transition: all .3s;
}
</style>
