<template>
  <q-carousel
    :class="['full-width', rounded ? 'border-radius' : undefined]"
    style="height: auto"
    :model-value="activeSlide"
    ref="carousel"
    :autoplay="true"
    transition-next="slide-left"
    transition-prev="slide-right"
    animated
    infinite
  >

    <!-- slides -->
    <q-carousel-slide v-for="(slide, index) of slides" class="no-padding" :name="index" v-touch-swipe.right.left="changeSlide">

      <!-- for external links -->
      <a v-if="slide.isLinkExternal" style="text-decoration: none" :href="slide.link" target="_blank">
        <q-img :src="slide.image && `${baseUrl}/cdn/download/${slide.image.fileName}`" height="100%">
          <template v-slot:loading>
            <q-spinner-tail
              color="grey-4"
              size="xl"
            />
          </template>
        </q-img>
      </a>

      <!-- for internal links -->
      <router-link v-else style="text-decoration: none" :to="slide.link">
        <q-img :src="slide.image && `${baseUrl}/cdn/download/${slide.image.fileName}`" :ratio="2.4" height="100%">
          <template v-slot:loading>
            <q-spinner-tail
              color="grey-4"
              size="xl"
            />
          </template>
        </q-img>
      </router-link>

    </q-carousel-slide>

    <!-- custom controls -->
    <template v-if="slides.length > 1" v-slot:control>

      <!-- previous slide -->
      <q-carousel-control :class="['column full-height justify-center', isMobile ? 'q-px-sm' : 'q-px-md']"
                          position="left" :offset="[0, 0]">
        <q-icon class="cursor-pointer" name="la la-angle-right" :size="isMobile ? 'md' : 'xl'"
                @click="$refs.carousel.previous()"/>
      </q-carousel-control>

      <!-- next slide -->
      <q-carousel-control :class="['column full-height justify-center', isMobile ? 'q-px-sm' : 'q-px-md']"
                          position="right" :offset="[0, 0]">
        <q-icon class="cursor-pointer" name="la la-angle-left" :size="isMobile ? 'md' : 'xl'"
                @click="$refs.carousel.next()"/>
      </q-carousel-control>

      <!-- navigation -->
      <q-carousel-control position="bottom" :offset="[0, 10]">
        <div class="row q-gutter-x-xs justify-center">
          <div v-for="(n, index) in slides.length"
               :class="['row border-radius', index === activeSlide ? 'bg-primary' : 'bg-white']"
               :style="{width: isMobile ? '40px' : '50px', height: '4px'}" @click="activeSlide = index"/>
        </div>
      </q-carousel-control>

    </template>

  </q-carousel>
</template>

<script>
export default {
  name: "SBannerSlider",
  data: () => ({
    baseUrl: process.env.API_URL
  }),
  props: {
    activeSlide: {
      type: Number,
      default: 0
    },
    slides: {
      type: Array,
      required: true
    },
    rounded: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isMobile() {
      return this.$q.screen.lt.md
    }
  },
  methods: {
    changeSlide(evt) {

      // swiping right
      if (evt.direction === 'right')
        this.$refs.carousel.next()

      // swiping left
      else if (evt.direction === 'left')
        this.$refs.carousel.previous()
    }
  },
  watch: {
    activeSlide(to, from) {
      this.$emit('slideChange', to)
    }
  }
}
</script>
